<script setup lang="ts">
defineProps({
  successStory: {
    type: Object as PropType<Sanity.Default.Schema.SuccessStories>,
    required: true,
  },
})
const { urlFor } = useSanityImage()
const { truncate } = useTruncate()

// the year for the success story from the date
const year = (date: string) => new Date(date).getFullYear()
</script>

<template>
  <div v-if="successStory && successStory.image" class="group aspect-h-1 aspect-w-1 overflow-hidden bg-gray-100 col-span-2 relative">
    <nuxt-img
      :src="urlFor(successStory.image.asset).width(500).height(500).fit('crop').url()"
      quality="85"
      :alt="successStory.image.alt"
      class="object-cover object-center group-hover:opacity-75"
      :width="500"
      :height="500"
      sizes="sm:80vw md:500px"
      loading="lazy"
    />

    <div class="absolute opacity-10 bg-black inset-0" />
    <div class="absolute bg-gradient-to-t from-primary-800 via-transparent to-transparent inset-0" />
    <div class="bg-transparent p-4 text-base flex flex-col justify-end">
      <div class="lg:translate-y-8 lg:opacity-0 transition duration-500 ease-in-out lg:group-hover:translate-y-0 lg:group-hover:opacity-100">
        <p class="line-clamp-1 mb-0">
          <span class="font-semibold text-gray-50 text-lg">{{ successStory.heading }}</span>
        </p>
        <!-- <span v-if="successStory.pricing" class="text-gray-400 text-sm mb-2.5">{{ successStory.pricing }}</span> -->

        <p class=" text-gray-50 mb-2 text-sm xl:text-base line-clamp-3">
          "{{ truncate(successStory.clientQuote, 240) }}"
        </p>
        <p class="text-xs mb-0 text-gray-200">
          {{ successStory.clientName }}, {{ year(successStory.date) }}
        </p>
      </div>
    </div>
    <nuxt-link
      :to="`/success-stories/${successStory.slug.current}`"
      class="inset-0 absolute"
    >
      <span class="sr-only">Read more about {{ successStory.heading }}</span>
    </nuxt-link>
  </div>
</template>
